<template>
    <Dialog ref="dialog" :config="config" :beforeClose="beforeClose" :visible='visible' :cancelDialog="cancelDialog" :confirmDialog='confirmDialogs' :isdeling='isdeling'>
        <p class="text">请输入移出方和接收方账号，您即将移交的公司是： {{rowData.nowCompany}}公司</p>
        <p class="tips">注意：移交公司后，您将不能再查看该公司数据！</p>
        <el-form label-width="6rem" :model="rowData" class="form1" :rules="rules" ref="transferForm">
            <el-form-item label='移出方账号' required>
                <el-input v-model="rowData.remove" disabled></el-input>
            </el-form-item>
            <el-form-item label='接收方账号' prop="receive" required>
                <el-input v-model="rowData.receive"></el-input>
            </el-form-item>
            <p v-if="errors" class="tips margin">{{errorMsg}}</p>
        </el-form>
    </Dialog>
</template>

<script>
import Dialog from "@/components/Dialog";
export default {
  props: {
    errors:{
      type: Boolean,
    },
    errorMsg : {
      type : String,
    },
    rowData:{
      type: Object,
    },
    type:{
      type: String,
    },
    visible: {
      type: Boolean,
    },
    isdeling:{
      type: Boolean,
    },
    cancelDialog:{
      type: Function,
      default: () => {},
    },
    confirmDialog:{
      type: Function,
      default: () => {},
    }
  },
  components: {
      Dialog,
  },
  data() {
    var telValidate = (rule, value, callback) => {
        const reg = /^1[3|4|5|7|8|9][0-9]{9}$/
        this.canGet = true
        if (!value || value.length > 11 || !reg.test(value)) {
          callback(new Error('请输入有效的手机号'))
        } else if(value == this.rowData.remove){
          callback(new Error('接收方账号不能与移出方账号相同'))
        }else{
          callback()
        }
      }
    return {
      config: {
        top: '20vh',
        width: '24rem',
        title: '移交公司',
        center: true,
        btnTxt: ['确定','取消'],
      },
      rules:{
        remove: [
            { required: true, message: "请输入移出方账号" , trigger: 'blur' },
            { pattern: '^1[3|4|5|7|8|9][0-9]{9}$', message: '移出方账号格式不正确', trigger: 'blur' }
        ],
        receive: [
            { required: true, message: "请输入接收方账号"},
            { validator: telValidate, trigger: 'blur' }
          ],
        // receive: [
        //     { required: true, message: "请输入接收方账号" , trigger: 'blur' },
        //     { pattern: '^1[3|4|5|7|8|9][0-9]{9}$', message: '接收方账号格式不正确', trigger: 'blur' }
        // ],
      }
    };
  },
  methods: {
    beforeClose(){
      console.log('关闭前');
    },
    confirmDialogs(){
        this.$refs.transferForm.validate((valid) => {
            if (valid) {
                this.confirmDialog();
            }else{
                this.isdeling = false;
                console.log(valid);
            }
        });
    }
  }
};
</script>

<style lang="less" scoped>
  .tips{
    color: #FF0000;
    font-size: 13px;
  }
  .margin{
      margin: 0 0 0 95px;;
  }
  .text{
    color: #333333;
    font-size: 14px;
    line-height: 28px;
    margin: 6px 0;
  }
  .form1 {
    padding-top: 8px;
  }
</style>
